import React, { useRef, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import './Pages.css';
import EventLists from '../Components/Events/EventLists';

const fetchEvents = async () => {
  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
  const response = await fetch(`${baseUrl}/events`);
  const data = await response.json();

  // 转换后端事件数据为 FullCalendar 需要的格式
  return data.map(event => ({
    id: event.event_id,
    title: event.Event,
    description: event.Details,
    start: `${convertDate(event.Start_date)}`, // 将日期和时间组合成ISO格式
    end: `${convertDate(event.End_date)}`,
    time: `${convertTime(event.Start_time)} - ${convertTime(event.End_time)}`,
    extendedProps: {
      address: event.Address,
      details: event.Details,
      image: event.Image,
      host: event.Host,
      ticket: event.Ticket,
      city: event.City
    }
  }));
};

// 将 "DD/MM/YYYY" 转换成 "YYYY-MM-DD" 格式
const convertDate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

// 将时间转换为24小时制的字符串
const convertTime = (timeString) => {
  const [time, period] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (period === '下午' && hours !== '12') {
    hours = parseInt(hours) + 12;
  } else if (period === '上午' && hours === '12') {
    hours = '00';
  }

  return `${hours}:${minutes}`;
};

const CalendarComponent = () => {
  const calendarRef = useRef(null);
  const [calendarTitle, setCalendarTitle] = useState('');
  const [events, setEvents] = useState([]); // 初始为空数组

  useEffect(() => {
    const getEvents = async () => {
      const fetchedEvents = await fetchEvents(); // 获取并转换事件数据
      setEvents(fetchedEvents); // 设置事件
    };

    getEvents(); // 组件加载时获取事件

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalendarTitle(calendarApi.view.title);
    }
  }, []);

  const handlePrev = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleNext = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setCalendarTitle(calendarApi.view.title);
  };

  const handleViewChange = (view) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);
    setCalendarTitle(calendarApi.view.title);
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="bg-white mx-4 sm:mx-8 md:mx-24 lg:mx-36 rounded-2xl shadow-md overflow-hidden">
        {/* 自定义工具栏 */}
        <div className="flex flex-col items-center mt-4 p-4">
          {/* 日历标题 */}
          <div className="w-full text-center mb-4">
            <h2 className="text-xl font-bold">{calendarTitle}</h2>
          </div>
          {/* 包含按钮的框 */}
          <div className="bg-gray-50 p-2 rounded-xl shadow-inner w-full overflow-x-auto">
            <div className="flex justify-center items-center whitespace-nowrap">
              {/* Today 按钮 */}
              <button
                className="mx-1 px-2 py-1 border border-yellow-500 text-yellow-500 text-xs sm:text-sm rounded-full hover:bg-yellow-100 active:bg-yellow-100 transition duration-200 flex-shrink-0"
                onClick={handleToday}
              >
                Today
              </button>
              {/* 增加间距 */}
              <div className="w-px h-4 bg-gray-300 mx-1"></div>
              {/* 中间三个按钮 */}
              <button
                className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                onClick={handlePrev}
              >
                &lt;
              </button>
              <button
                className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                onClick={() => handleViewChange('dayGridMonth')}
              >
                Month
              </button>
              <button
                className="mx-1 px-2 py-1 bg-yellow-500 text-black text-xs sm:text-sm rounded-full hover:bg-yellow-600 active:bg-yellow-700 transition duration-200 flex-shrink-0"
                onClick={handleNext}
              >
                &gt;
              </button>
              {/* 增加间距 */}
              <div className="w-px h-4 bg-gray-300 mx-1"></div>
              {/* Week 按钮 */}
              <button
                className="mx-1 px-2 py-1 border border-yellow-500 text-yellow-500 text-xs sm:text-sm rounded-full hover:bg-yellow-100 active:bg-yellow-100 transition duration-200 flex-shrink-0"
                onClick={() => handleViewChange('dayGridWeek')}
              >
                Week
              </button>
            </div>
          </div>
        </div>
        {/* FullCalendar 组件 */}
        <div className="p-4">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            headerToolbar={false}
            views={{
              dayGridMonth: { fixedWeekCount: false },
              dayGridWeek: {
                titleFormat: { month: 'long', year: 'numeric' },
                dayHeaderFormat: { weekday: 'short' }
              }
            }}
            locale="zh-cn"
            datesSet={(dateInfo) => {
              setCalendarTitle(dateInfo.view.title);
            }}
          />
        </div>
      </div>
      {/* 活动列表 */}
      <div className="mt-8 mx-4 sm:mx-8 md:mx-24 lg:mx-36">
        <EventLists events={events} className="mb-24 bg-white rounded-2xl shadow-md p-4" />
      </div>
    </div>
  );
};

export default CalendarComponent;
