import React, { useState, useRef, useEffect } from 'react';

const AccountInfo = () => {
    const [formData, setFormData] = useState({
        companyName: '企信科技',
        email: 'Qixin@gmail.com.au',
        address: 'Canberra ACT, Australia',
        phone: '+61 484411111',
        password: 'abc',
        confirmPassword: 'abc',
        imgurl: ''
    });

    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null); // 引用隐藏的文件输入框

    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // 获取上传的文件
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
            setFormData({ ...formData, imgurl: imageUrl });
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // 在这里处理表单提交
    };

    return (
        <div className="bg-white p-6 shadow-md rounded-lg w-full">
            <h2 className="text-2xl font-bold mb-4">账户信息</h2>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 space-y-4">
                        {[
                            { label: '企业名', name: 'companyName', placeholder: '请输入企业名' },
                            { label: '密码', name: 'password', placeholder: '请输入密码', type: 'password' },
                            { label: '邮箱', name: 'email', placeholder: '请输入邮箱' },
                            { label: '企业地址', name: 'address', placeholder: '请输入企业地址' },
                            { label: '电话', name: 'phone', placeholder: '请输入电话号码' },
                        ].map((field) => (
                            <div className="flex items-center" key={field.name}>
                                <label className="mr-3 w-2/5 block font-sans text-gray-700 text-sm">
                                    {field.label} :
                                </label>
                                <input
                                    type={field.type || 'text'}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    className="p-2 border border-gray-300 rounded-2xl text-md w-4/5"
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="w-full md:w-1/2 flex flex-col justify-center items-center space-y-4 mt-6 md:mt-0">
                        <label className="mr-3 w-1/3 block font-sans text-gray-700 text-sm">
                            企业头像 :
                        </label>
                        <div
                            className="w-48 h-48 border border-gray-300 flex justify-center items-center cursor-pointer"
                            onClick={handleImageClick}
                        >
                            {/* 显示上传的图片，如果没有则显示一个默认的占位符 */}
                            {image ? (
                                <img src={image} alt="uploaded" className="w-full h-full object-cover rounded" />
                            ) : (
                                <div className="text-gray-500">点击上传照片</div>
                            )}
                        </div>

                        {/* 隐藏的文件输入框 */}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                        保存更改
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AccountInfo;
