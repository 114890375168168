import React, { useEffect, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FilterComponent from '../Components/Companies/FilterComponent';
import AdvertisementGrid from '../Components/Companies/AdvertisementGrid';
import Breadcrums from '../Components/Common/Breadcrums';
import FeatureBar from '../Components/Common/FeatureBar';
import GuideSteps from '../Components/Companies/GuideSteps';
import WenMortgage from '../Assets/Wen_Mortgage.jpg';
import LivecomfyTop from '../Assets/Livecomfy_Top.png';
import Pagination from '../Components/Common/Pagination';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { setRegion, setIndustry, setPage, fetchCompanies } from '../Components/Companies/companiesSlice';
// 懒加载 CompanyItem
const CompanyItem = React.lazy(() => import('../Components/Companies/CompanyItem'));

const industryMapping = {
  'realestate': '地产开发',
  'design': '建筑设计',
  'builder': '房屋建造',
  'material': '建筑材料',
  'decoration': '室内装修',
  'agency': '房产经纪',
  'mortgage': '房屋信贷',
  'law': '法律咨询',
  'accounting': '会计事务',
};

const Company = () => {
  const dispatch = useDispatch();
  const { industry } = useParams();

  const filterRef = useRef(null);

  const { companies, loadingCompanies, cachedCompanies, errorCompanies, selectedRegion, selectedIndustry, currentPage, totalPages } = useSelector(state => state.companies);

  const industryName = industryMapping[industry] || '全部';
  const isAllIndustry = industryName === '全部' || !industryName;

  useEffect(() => {
    // 只在未缓存状态下抓取后端数据
    if (!cachedCompanies) {
      dispatch(fetchCompanies({ region: selectedRegion, industry: selectedIndustry }));
    }
  }, [dispatch, selectedRegion, selectedIndustry, cachedCompanies]);

  const handlePageChange = (page) => {
    dispatch(setPage(page));
  };

  useEffect(() => {
    if (filterRef.current) {
      filterRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentPage]);

  useEffect(() => {
    dispatch(setIndustry(industryName));
  }, [industryName, dispatch]);

  useEffect(() => {
    dispatch(fetchCompanies({ region: selectedRegion, industry: selectedIndustry, page: currentPage }));
  }, [selectedRegion, selectedIndustry, currentPage, dispatch]);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* 加载转圈圈 */}
      {loadingCompanies && <LoadingSpinner />}

      <div className="flex-grow flex flex-col">
        <div className="px-4 sm:px-6 lg:px-8 py-4">
          <Breadcrums color="text-gray-700" />
          {!isAllIndustry && <GuideSteps industry={industry} />}
        </div>

        <div className="flex-grow flex flex-col items-center w-full">
          <div className="w-full max-w-[105vw] sm:max-w-[105vw] md:max-w-[90vw] lg:max-w-[85vw] xl:max-w-[80vw] 2xl:max-w-[80vw] px-4 sm:px-6 lg:px-8">
            <div className="my-8 md:my-12">
              <AdvertisementGrid />
            </div>

            {isAllIndustry && (
              <div className="w-full flex flex-col sm:flex-row items-center gap-4 my-8">
                <div className="w-full sm:w-1/2 aspect-[5/1]">
                  <FeatureBar
                    imageUrl={WenMortgage}
                    link="/company/349"
                  />
                </div>
                <div className="w-full sm:w-1/2 aspect-[5/1]">
                  <FeatureBar
                    imageUrl={LivecomfyTop}
                    link="/company/60"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="w-full max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="my-8" ref={filterRef}>
              <FilterComponent
                selectedRegion={selectedRegion}
                setSelectedRegion={(region) => dispatch(setRegion(region))}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={(industry) => dispatch(setIndustry(industry))}
              />
            </div>


            <div className="my-8">
              <Suspense fallback={<div>Loading...</div>}>
                <CompanyItem
                  companies={companies}
                  loadingCompanies={loadingCompanies}
                  errorCompanies={errorCompanies}
                  selectedRegion={selectedRegion}
                  selectedIndustry={selectedIndustry}
                  currentPage={currentPage}
                />
              </Suspense>
            </div>

            <div className="my-8">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;