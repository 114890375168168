import React, { useEffect, useState, useRef } from 'react';
import { FaBuilding, FaUsers, FaCity, FaCheckCircle, FaPhone, FaEnvelope } from 'react-icons/fa';

const CompanyIntroduction = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [animationStage, setAnimationStage] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [featureSectionTop, setFeatureSectionTop] = useState(0);
  const featureSectionRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    const observeFeatureSection = () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setFeatureSectionTop(entry.boundingClientRect.top + window.pageYOffset);
          }
        },
        { threshold: 0.1 }
      );

      if (featureSectionRef.current) {
        observer.observe(featureSectionRef.current);
      }

      return () => observer.disconnect();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleResize();
    const cleanup = observeFeatureSection();

    // Start the animation sequence
    const animationSequence = [0, 100, 200, 300, 600];
    animationSequence.forEach((delay, index) => {
      setTimeout(() => setAnimationStage(index + 1), delay);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      cleanup();
    };
  }, []);

  const calculateOpacity = (start, end, current) => {
    return 1 - Math.min(Math.max((current - start) / (end - start), 0), 1);
  };

  const headerOpacity = isMobile ? 1 : calculateOpacity(0, 600, scrollPosition);
  const featureOpacity = isMobile ? 1 : calculateOpacity(
    featureSectionTop - (window.innerHeight * 0.01),  
    featureSectionTop + (window.innerHeight * 0.7),  
    scrollPosition
  );

  const features = [
    { icon: FaBuilding, title: "齐全的行业分类", description: "涵盖9大类建筑地产相关行业，为您提供全方位的市场洞察。", items: ['地产开发', '房屋设计', '房屋建造', '建筑材料', '房产经纪', '房屋装修', '房屋信贷', '法律咨询', '会计事务'] },
    { icon: FaUsers, title: "广泛的合作网络", description: "与700+家企业建立合作关系，为您搭建强大的商业服务桥梁。", items: ["顶级地产开发公司", "知名房屋设计事务所", "大型房屋建造企业", "高品质建筑材料供应商", "资深房产经纪人", "专业室内设计和装修公司", "房屋信贷服务机构", "专业法律咨询公司", "知名会计事务所"] },
    { icon: FaCity, title: "本地化服务", description: "覆盖澳洲主要城市，提供针对性的本地市场信息和支持。", items: ["堪培拉","悉尼", "墨尔本", "布里斯班", "珀斯", "阿德莱德", "卧龙岗", "黄金海岸"] },
    { icon: FaCheckCircle, title: "一站式解决方案", description: "从市场分析到商务对接，我们为您提供全方位的专业服务。", items: ["市场趋势分析", "项目可行性研究", "商业伙伴匹配", "法律法规咨询", "财务规划建议"] },
  ];

  return (
    <div>
      {/* 顶部内容 - 黑底白字 */}
      <div 
        className="bg-black text-white min-h-screen flex items-center justify-center relative overflow-hidden"
        style={{
          opacity: headerOpacity,
          transition: isMobile ? 'none' : 'opacity 0.3s ease-out'
        }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl text-yellow font-bold tracking-wide uppercase mb-6">
            {['关', '于', '企', '信'].map((char, index) => (
              <span
                key={index}
                className="inline-block"
                style={{
                  transform: `translateY(${animationStage > index ? '0' : '-300px'})`,
                  opacity: animationStage > index ? 1 : 0,
                  transition: 'transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out',
                  transitionDelay: `${index * 0.03}s`
                }}
              >
                {char}
              </span>
            ))}
          </h2>
          <div className={`transition-opacity duration-1000 ${animationStage >= 5 ? 'opacity-100' : 'opacity-0'}`}>
            <p className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-8">
              澳洲最详细的房产建筑行业信息网站
            </p>
            <p className="text-xl md:text-2xl text-center max-w-3xl mx-auto mb-12">
              提供全面的行业信息和服务，连接您与澳洲建筑地产行业的无限机遇。
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-8">
              <div className="flex items-center">
                <FaPhone className="text-yellow mr-2" />
                <span>+61 484411111</span>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-yellow mr-2" />
                <span>info@qixin.com.au</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 特色部分 - 灰色背景 */}
      <div
        ref={featureSectionRef}
        className="bg-gray-200 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
        style={{
          opacity: featureOpacity,
          transition: isMobile ? 'none' : 'opacity 0.3s ease-out'
        }}
      >
        <div className="w-full max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {features.map((section, index) => {
              return (
                <div key={index} className="bg-white rounded-lg shadow-xl p-6 flex flex-col justify-between transform hover:scale-105 transition-all duration-300">
                  <div>
                    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-yellow text-white mb-4 mx-auto">
                      <section.icon className="h-8 w-8" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2 text-center">{section.title}</h3>
                    <p className="text-sm text-gray-600 mb-4 text-center">{section.description}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <ul className="text-gray-600 space-y-1 text-sm">
                      {section.items.slice(0, 5).map((item, itemIndex) => (
                        <li key={itemIndex} className="flex items-center">
                          <span className="h-1.5 w-1.5 bg-yellow rounded-full mr-2 flex-shrink-0"></span>
                          <span className="flex-grow">{item}</span>
                        </li>
                      ))}
                    </ul>
                    {section.items.length > 5 && (
                      <ul className="text-gray-600 space-y-1 text-sm">
                        {section.items.slice(5).map((item, itemIndex) => (
                          <li key={itemIndex} className="flex items-center">
                            <span className="h-1.5 w-1.5 bg-yellow rounded-full mr-2 flex-shrink-0"></span>
                            <span className="flex-grow">{item}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyIntroduction;