import React from 'react';
import { Link } from 'react-router-dom';

const Message = () => {
    const messages = [
        {
            type: 'comment',
            content: 'User A commented on your post: Great job!',
            timestamp: '2024-09-24 10:35',
        },
        {
            type: 'eventComment',
            content: 'User B commented on your event: Looking forward to it!',
            timestamp: '2024-09-24 11:00',
        },
        {
            type: 'like',
            content: 'User C liked your comment.',
            timestamp: '2024-09-24 12:15',
        },
    ];

    return (
        <div className="p-4 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">消息通知</h2>
            <ul>
                {messages.map((message, index) => (
                    <li key={index} className="mb-4">
                        <div className="flex justify-between items-center">
                            <Link to='#' className="text-gray-700">
                                <span className="text-gray-700">{message.content}</span>
                            </Link>
                            <span className="text-gray-400 text-sm">{message.timestamp}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Message;
