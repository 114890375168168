import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.email.trim()) tempErrors.email = "Email is required";
    if (!formData.message.trim()) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/send-email`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setSubmitStatus('success');
          setFormData({ name: '', email: '', phone: '', message: '' });
        } else {
          setSubmitStatus('error');
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmitStatus('error');
      }
    }
  };

  return (
    <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4">发送消息</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
            <FaUser className="text-gray-600 ml-3" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="姓名 *"
              className="w-full px-4 py-2 text-gray-600 focus:outline-none"
            />
          </div>
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>
        <div className="mb-4">
          <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
            <FaEnvelope className="text-gray-600 ml-3" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="邮箱 *"
              className="w-full px-4 py-2 text-gray-600 focus:outline-none"
            />
          </div>
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>
        <div className="mb-4">
          <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
            <FaPhone className="text-gray-600 ml-3" />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="电话"
              className="w-full px-4 py-2 text-gray-600 focus:outline-none"
            />
          </div>
        </div>
        <div className="mb-4">
          <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="内容 *"
              className="w-full px-4 py-2 text-gray-600 focus:outline-none h-32"
            ></textarea>
          </div>
          {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
        </div>
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="w-1/3 bg-yellow text-white py-2 rounded-lg hover:bg-lightyellow transition duration-300 disabled:bg-gray-400"
            disabled={!formData.name || !formData.email || !formData.message}
          >
            发送
          </button>
          {submitStatus === 'success' && (
            <p className="text-yellow text-lg md:text-xl lg:text-2xl mt-4">发送成功!</p>
          )}
          {submitStatus === 'error' && (
            <p className="text-yellow text-base md:text-lg lg:text-xl mt-4">发送失败，请重试.</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;