import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Activity = () => {
  const [events, setEvents] = useState([]); // 状态管理，保存新闻数据

  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // 检查是否有缓存数据
        const cachedEvents = localStorage.getItem('events');
        if (cachedEvents) {
          setEvents(JSON.parse(cachedEvents));
        } else {
          const response = await fetch(`${baseUrl}/events`);
          if (!response.ok) {
            throw new Error('网络错误，无法获取活动。');
          }
          const data = await response.json();
          setEvents(data); // 将获取到的新闻数据存入状态

          // 缓存数据
          localStorage.setItem('events', JSON.stringify(data));
        }
      } catch (err) {
        console.error(err.message); // 处理错误
      }
    };

    fetchEvents();
  }, [baseUrl]); // 当 baseUrl 变化时重新请求活动

  return (
    <div className="bg-gray-200 p-4 h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold text-black">近期活动</h2>
        <Link
          to="/calendar"
          className="text-darkyellow hover:text-yellow-500 text-sm font-semibold transition-colors duration-300 ease-in-out"
        >
          查看全部 &gt;&gt;&gt;
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 flex-grow overflow-y-auto">
        {events.slice(0, 9).map((event, index) => (
          <Link
            key={event.news_id}
            to={`/calendar/${event.event_id}`}
            className={`block rounded-lg shadow-md aspect-[3/2] relative overflow-hidden
                        ${index === 0 ? 'col-span-2 md:col-span-1 justify-self-center' : ''}`}
          >
            <div
              className="flex flex-col justify-center items-center p-2 h-full w-full"
              style={{
                backgroundImage: event.Image ? `url(${event.Image})` : 'none',
                backgroundColor: event.Image ? 'transparent' : 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50"></div>
              <h3 className="text-yellow text-[10px] sm:text-sm font-semibold mb-1 z-10 text-center">
                {event.Event}
              </h3>
              <p className="text-white text-[10px] sm:text-xs z-10 text-center sm:mt-3">
                {event && event.Details ? (
                  <>
                    {window.innerWidth <= 640 // sm: 640px and below
                      ? event.Details.slice(0, 25)
                      : event.Details.slice(0, 55)}
                    {(window.innerWidth <= 640 && event.Details.length > 25) ||
                      (window.innerWidth > 640 && event.Details.length > 55)
                      ? '...'
                      : ''}
                  </>
                ) : (
                  ''
                )}
              </p>
              <p className="text-gray-200 text-[10px] sm:text-xs z-10 text-center sm:mt-3">{event.Start_date}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Activity;
