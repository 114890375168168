import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CountdownTimer from '../Components/Events/CountdownTimer';
import { FaCalendarDay, FaMapPin } from 'react-icons/fa';
import { TiBusinessCard } from "react-icons/ti";
import Breadcrums from '../Components/Common/Breadcrums';
import FormattedText from '../Components/Common/FormattedText';

const EventLandingPage = () => {
  const { id } = useParams(); // 获取 URL 中的 event.id
  const [eventData, setEventData] = useState(null); // 用于存储获取到的事件数据

  const [heroVisible, setHeroVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHeroVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleItemClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  // 用于获取事件数据
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/events/${id}`); // 使用 event.id 进行 API 请求
        if (response.ok) {
          const data = await response.json();
          setEventData(data); // 设置获取到的数据
          console.log("Event data by Id:", data);
        } else {
          console.error("Failed to fetch event data");
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [id]); // 依赖于 id，当 id 变化时重新获取数据

  // 当 eventData 还未获取到数据时，可以显示加载状态
  if (!eventData) {
    return <div className='text-center py-12'>Loading...</div>;
  }

  return (
    <div className="font-sans">
      {/* Hero Section */}
      <div className="relative h-screen overflow-hidden">
        <img
          src={eventData.Image}
          alt="Event background"
          className="absolute inset-0 w-full h-full object-cover object-center"
          style={{
            filter: 'brightness(70%)',
          }}
        />

        <div className="relative z-20">
          <Breadcrums color="text-white" additionalText={eventData.Event} />
        </div>

        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white text-center px-4 z-10">
          <img
            src={eventData.Image}
            alt="Event Logo"
            className={`w-20 h-20 rounded-full mb-6 transition-opacity duration-1000 ease-out ${heroVisible ? 'opacity-100' : 'opacity-0'
              }`}
          />
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl font-bold mb-4 transition-transform duration-1000 ease-out ${heroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}
          >
            {eventData.Event}
          </h1>
          {/* <p
            className={`text-md md:text-xl mb-8 max-w-2xl transition-opacity duration-1000 ease-out ${
              heroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
            }`}
          >
            Welcome to Activation Leadership 2023, the biggest event about personal growth in central Europe.
          </p> */}
          <div
            className={`flex flex-col sm:flex-row items-center justify-center space-x-4 mb-8 gap-2 mx-4 transition-opacity duration-1000 ease-out ${heroVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}
          >
            <div className="flex items-center">
              <FaCalendarDay className="mr-2" />
              <span>{eventData.Start_date} - {eventData.End_date}</span>
            </div>
            <div className="flex items-center">
              <FaMapPin className="mr-2" />
              <span>{eventData.City}</span>
            </div>
            <div className="flex items-center">
              <TiBusinessCard className="mr-2" />
              <span>{eventData.Host}</span>
            </div>
          </div>
          <button
            className={`border boder-white bg-teal-500 hover:bg-yellow text-white font-bold py-2 px-6 rounded-full transition duration-300 ${heroVisible ? 'opacity-100' : 'opacity-0'
              }`}
          >
            Join event
          </button>
        </div>
      </div>

      <CountdownTimer date={eventData.Start_date} time={eventData.Start_time} />

      {/* About Section */}
      <div className="py-16 px-4 bg-gray-100">
        <div className="max-full mx-2 sm:mx-4 md:mx-8 lg:mx-16">
          <div className="mx-auto bg-white rounded-2xl shadow-lg p-6 text-center">
            <h1 className="text-lg sm:2xl lg:text-3xl font-bold mb-6 text-center text-yellow">活动详情</h1>

            <div className="max-w-3xl mx-auto text-left">
              {[
                { title: "日期：", content: `从 ${eventData.Start_date} 到 ${eventData.End_date}` },
                { title: "时间：", content: `从 ${eventData.Start_time} 到 ${eventData.End_time}` },
                { title: "费用：", content: eventData.Ticket },
                { title: "主办方：", content: eventData.Host },
                { title: "地点：", content: eventData.Address },
              ].map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-x-4 mb-4   transition-colors duration-200 p-2 rounded"
                  onClick={() => handleItemClick(item.url)}
                >
                  <span className="text-sm sm:text-md lg:text-lg font-semibold text-gray-600">{item.title}</span>
                  <span className={"text-sm sm:text-md lg:text-lg text-gray-600 "}>
                    {item.content}
                  </span>
                </div>
              ))}
              <hr className="w-5/6 mx-auto h-0.5 bg-gray-100 border-0 my-8 rounded-2xl mt-8 sm:mt-4 lg:mt-8 xl:mt-12" />
              <br></br>
              <p className="text-md lg:text-lg text-gray-700 mb-12">
                <FormattedText text={eventData.Details}/>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLandingPage;