import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ company, onHover, isHovered }) => {
  return (
    <div
      className="relative aspect-[3/2] overflow-hidden rounded-xl cursor-pointer mx-auto"
      onMouseEnter={() => onHover(company.id)}
      onMouseLeave={() => onHover(null)}
    >
      <img
        src={company.image}
        alt={`${company.name} Logo`}
        className="w-full h-full object-cover"
      />

      <Link to={`/company/${company.id}`} target="_blank" rel="noopener noreferrer">
      <div
        className={`absolute inset-0 bg-yellow bg-opacity-90 flex flex-col justify-center items-center px-4 transition-opacity duration-300 ${
          isHovered ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <p className="text-white text-xs md:text-sm text-center">
          {company && company.description ? (
            <>
              {company.description.slice(0, 55)}
              {company.description.length > 55 && '...'}
            </>
          ) : ''}
        </p>
        <h3 className="mt-3 text-xs md:text-sm font-medium text-white underline hover:text-gray-200">
          详情 →
        </h3>
      </div>
      </Link>
    </div>
  );
};

export default Item;
