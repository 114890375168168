import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import arrow_icon from '../../Assets/breadcrum_arrow.png';

// 定义路径与中文名称的映射表
const breadcrumbMap = {
  '/company': '公司信息',
  '/companies': '公司信息',
  '/companies/realestate': '地产开发',
  '/companies/design': '建筑设计',
  '/companies/builder': '房屋建造',
  '/companies/material': '建筑材料',
  '/companies/decoration': '室内装修',
  '/companies/agency': '房产经纪',
  '/companies/mortgage': '房屋信贷',
  '/companies/law': '法律咨询',
  '/companies/accounting': '会计事务',
  '/calendar': '活动日历',
  '/news': '新闻',
  '/QA': '常见问题',
};

const Breadcrums = ({ color, additionalText }) => {
  const location = useLocation();

  // 获取当前路径并按 '/' 分割
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className='flex items-center text-sm relative flex-wrap w-[80%] mx-[20px] md:mx-[50px] rounded-lg pt-4'>

      <Link to="/">
        <span className={`hover:text-yellow cursor-pointer ${color}`}>首页</span>
      </Link>

      {/* 动态生成面包屑 */}
      {pathnames.map((_, index) => {
        // 构建每个面包屑的完整路径
        const to = `/${pathnames.slice(0, index + 1).join('/')}`.replace('company', 'companies');
        const isLast = index === pathnames.length - 1;
        const breadcrumbName = breadcrumbMap[to];

        // 检查当前层级是否包含数字
        const hasNumber = /\d/.test(pathnames[index]);

        // 如果有对应中文名，则使用对应的中文名
        // 如果是最后一层并且包含数字，则将 additionalText 渲染在最后
        if (isLast && hasNumber) {
          return (
            <React.Fragment key={to}>
              <img src={arrow_icon} alt="" className="w-2 h-2 mx-2" />
              <span className="font-semibold text-yellow">{additionalText}</span>
            </React.Fragment>
          );
        }

        // 如果映射表里有对应中文名，则显示，否则保持原样
        if (!breadcrumbName) return null;

        return (
          <React.Fragment key={to}>
            <img src={arrow_icon} alt="" className="w-2 h-2 mx-2" />
            {isLast ? (
              <span className="font-semibold text-yellow">{breadcrumbName}</span>
            ) : (
              <Link to={to}>
                <span className={`hover:text-yellow cursor-pointer ${color}`}>
                  {breadcrumbName}
                </span>
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrums;
