import React from 'react';
import GuideIconsItem from './GuideIconsItem';
import Loan_Step1 from '../../Assets/GuideIcons/Loan-Step1.svg';
import Loan_Step2 from '../../Assets/GuideIcons/Loan-Step2.svg';
import Loan_Step3 from '../../Assets/GuideIcons/Loan-Step3.svg';
import Loan_Step4 from '../../Assets/GuideIcons/Loan-Step4.svg';
import Loan_Step5 from '../../Assets/GuideIcons/Loan-Step5.svg';
import Loan_Step6 from '../../Assets/GuideIcons/Loan-Step6.svg';
import Loan_Step7 from '../../Assets/GuideIcons/Loan-Step7.svg';
import Loan_Step8 from '../../Assets/GuideIcons/Loan-Step8.svg';
import Accounting_Step1 from '../../Assets/GuideIcons/Accounting-Step1.png';
import Accounting_Step2 from '../../Assets/GuideIcons/Accounting-Step2.png';
import Accounting_Step3 from '../../Assets/GuideIcons/Accounting-Step3.png';
import Accounting_Step4 from '../../Assets/GuideIcons/Accounting-Step4.png';
import Accounting_Step5 from '../../Assets/GuideIcons/Accounting-Step5.png';
import Accounting_Step6 from '../../Assets/GuideIcons/Accounting-Step6.png';
import Accounting_Step7 from '../../Assets/GuideIcons/Accounting-Step7.png';
import Accounting_Step8 from '../../Assets/GuideIcons/Accounting-Step8.png';
import Accounting_Step9 from '../../Assets/GuideIcons/Accounting-Step9.png';
import Accounting_Step10 from '../../Assets/GuideIcons/Accounting-Step10.png';
import Agency_Step1 from '../../Assets/GuideIcons/Agency-Step1.png';
import Agency_Step2 from '../../Assets/GuideIcons/Agency-Step2.png';
import Agency_Step3 from '../../Assets/GuideIcons/Agency-Step3.png';
import Agency_Step4 from '../../Assets/GuideIcons/Agency-Step4.png';
import Agency_Step5 from '../../Assets/GuideIcons/Agency-Step5.png';
import Agency_Step6 from '../../Assets/GuideIcons/Agency-Step6.png';
import Agency_Step7 from '../../Assets/GuideIcons/Agency-Step7.png';
import Agency_Step8 from '../../Assets/GuideIcons/Agency-Step8.png';
import Agency_Step9 from '../../Assets/GuideIcons/Agency-Step9.png';
import Agency_Step10 from '../../Assets/GuideIcons/Agency-Step10.png';
import Agency_Step11 from '../../Assets/GuideIcons/Agency-Step11.png';
import Agency_Step12 from '../../Assets/GuideIcons/Agency-Step12.png';
import Agency_Step13 from '../../Assets/GuideIcons/Agency-Step13.png';
import Builder_Step1 from '../../Assets/GuideIcons/Builder-Step1.svg';
import Builder_Step2 from '../../Assets/GuideIcons/Builder-Step2.svg';
import Builder_Step3 from '../../Assets/GuideIcons/Builder-Step3.svg';
import Builder_Step4 from '../../Assets/GuideIcons/Builder-Step4.svg';
import Builder_Step5 from '../../Assets/GuideIcons/Builder-Step5.svg';
import Builder_Step6 from '../../Assets/GuideIcons/Builder-Step6.svg';
import Builder_Step7 from '../../Assets/GuideIcons/Builder-Step7.svg';
import Builder_Step8 from '../../Assets/GuideIcons/Builder-Step8.svg';
import Builder_Step9 from '../../Assets/GuideIcons/Builder-Step9.svg';
import Builder_Step10 from '../../Assets/GuideIcons/Builder-Step10.svg';
import Design_Step1 from '../../Assets/GuideIcons/Design-Step1.png';
import Design_Step2 from '../../Assets/GuideIcons/Design-Step2.png';
import Design_Step3 from '../../Assets/GuideIcons/Design-Step3.png';
import Design_Step4 from '../../Assets/GuideIcons/Design-Step4.png';
import Design_Step5 from '../../Assets/GuideIcons/Design-Step5.png';
import Design_Step6 from '../../Assets/GuideIcons/Design-Step6.png';
import Develop_Step1 from '../../Assets/GuideIcons/Develop-Step1.svg';
import Develop_Step2 from '../../Assets/GuideIcons/Develop-Step2.svg';
import Develop_Step3 from '../../Assets/GuideIcons/Develop-Step3.svg';
import Develop_Step4 from '../../Assets/GuideIcons/Develop-Step4.svg';
import Develop_Step5 from '../../Assets/GuideIcons/Develop-Step5.svg';
import Develop_Step6 from '../../Assets/GuideIcons/Develop-Step6.svg';
import Develop_Step7 from '../../Assets/GuideIcons/Develop-Step7.svg';
import Indoor_Step1 from '../../Assets/GuideIcons/Indoor-Step1.png';
import Indoor_Step2 from '../../Assets/GuideIcons/Indoor-Step2.png';
import Indoor_Step3 from '../../Assets/GuideIcons/Indoor-Step3.png';
import Indoor_Step4 from '../../Assets/GuideIcons/Indoor-Step4.png';
import Indoor_Step5 from '../../Assets/GuideIcons/Indoor-Step5.png';
import Indoor_Step6 from '../../Assets/GuideIcons/Indoor-Step6.png';
import Indoor_Step7 from '../../Assets/GuideIcons/Indoor-Step7.png';
import Law_Step1 from '../../Assets/GuideIcons/Law-Step1.png';
import Law_Step2 from '../../Assets/GuideIcons/Law-Step2.png';
import Law_Step3 from '../../Assets/GuideIcons/Law-Step3.png';
import Law_Step4 from '../../Assets/GuideIcons/Law-Step4.png';
import Law_Step5 from '../../Assets/GuideIcons/Law-Step5.png';
import Material_Step1 from '../../Assets/GuideIcons/Material-Step1.png';
import Material_Step2 from '../../Assets/GuideIcons/Material-Step2.png';
import Material_Step3 from '../../Assets/GuideIcons/Material-Step3.png';
import Material_Step4 from '../../Assets/GuideIcons/Material-Step4.png';
import Material_Step5 from '../../Assets/GuideIcons/Material-Step5.png';
import Material_Step6 from '../../Assets/GuideIcons/Material-Step6.png';
import Material_Step7 from '../../Assets/GuideIcons/Material-Step7.png';
import Material_Step8 from '../../Assets/GuideIcons/Material-Step8.png';
import Material_Step9 from '../../Assets/GuideIcons/Material-Step9.png';

const GuideSteps = ({ industry }) => {
  // Step definitions mapped by industry
  const stepsMap = {
    mortgage: [
      { icon: Loan_Step1, title: "1.贷款材料"},
      { icon: Loan_Step2, title: "2.办理预批"},
      { icon: Loan_Step3, title: "3.预批通过"},
      { icon: Loan_Step4, title: "4.看房选房"},
      { icon: Loan_Step5, title: "5.交付小定"},
      { icon: Loan_Step6, title: "6.交换合同"},
      { icon: Loan_Step7, title: "7.交付大定"},
      { icon: Loan_Step8, title: "8.房产交割"}
    ],
    accounting: [
      { icon: Accounting_Step1, title: "1.贷款材料"},
      { icon: Accounting_Step2, title: "2.办理预批"},
      { icon: Accounting_Step3, title: "3.预批通过"},
      { icon: Accounting_Step4, title: "4.看房选房"},
      { icon: Accounting_Step5, title: "5.交付小定"},
      { icon: Accounting_Step6, title: "6.交换合同"},
      { icon: Accounting_Step7, title: "7.交付大定"},
      { icon: Accounting_Step8, title: "8.房产交割"},
      { icon: Accounting_Step9, title: "9.交付大定"},
      { icon: Accounting_Step10, title: "10.房产交割"}
    ],
    agency: [
      { icon: Agency_Step1, title: "1.寻找房产"},
      { icon: Agency_Step2, title: "2.销售合同"},
      { icon: Agency_Step3, title: "3.寻找贷款"},
      { icon: Agency_Step4, title: "4.确定产权"},
      { icon: Agency_Step5, title: "5.安排检查"},
      { icon: Agency_Step6, title: "6.购买房产"},
      { icon: Agency_Step7, title: "7.交换合同"},
      { icon: Agency_Step8, title: "8.支付定金"},
      { icon: Agency_Step9, title: "9.准备文件"},
      { icon: Agency_Step10, title: "10.付印花税"},
      { icon: Agency_Step11, title: "11.安排保险"},
      { icon: Agency_Step12, title: "12.最终检查"},
      { icon: Agency_Step13, title: "13.交割日"}
    ],
    builder: [
      { icon: Builder_Step1, title: "1.决定建房"},
      { icon: Builder_Step2, title: "2.购买土地"},
      { icon: Builder_Step3, title: "3.选施工队"},
      { icon: Builder_Step4, title: "4.清理现场"},
      { icon: Builder_Step5, title: "5.铺设地基"},
      { icon: Builder_Step6, title: "6.框架搭建"},
      { icon: Builder_Step7, title: "7.封闭外壳"},
      { icon: Builder_Step8, title: "8.内部装修"},
      { icon: Builder_Step9, title: "9.最终竣工"},
      { icon: Builder_Step10, title: "10.验收交付"},
    ],
    design: [
      { icon: Design_Step1, title: "1.前期设计"},
      { icon: Design_Step2, title: "2.方案设计"},
      { icon: Design_Step3, title: "3.深化设计"},
      { icon: Design_Step4, title: "4.施工文件"},
      { icon: Design_Step5, title: "5.建筑许可"},
      { icon: Design_Step6, title: "6.施工管理"},
    ],
    realestate: [
      { icon: Develop_Step1, title: "1.前期准备" },
      { icon: Develop_Step2, title: "2.土地属性" },
      { icon: Develop_Step3, title: "3.开发申请" },
      { icon: Develop_Step4, title: "4.尽职调查" },
      { icon: Develop_Step5, title: "5.银行贷款" },
      { icon: Develop_Step6, title: "6.选建筑商" },
      { icon: Develop_Step7, title: "7.房屋交付" }
    ],
    decoration: [
      { icon: Indoor_Step1, title: "1.确定需求" },
      { icon: Indoor_Step2, title: "2.深入调研" },
      { icon: Indoor_Step3, title: "3.头脑风暴" },
      { icon: Indoor_Step4, title: "4.建筑设计" },
      { icon: Indoor_Step5, title: "5.概念设计" },
      { icon: Indoor_Step6, title: "6.施工图" },
      { icon: Indoor_Step7, title: "7.开始施工" }
    ],
    law: [
      { icon: Law_Step1, title: "1.查看合同" },
      { icon: Law_Step2, title: "2.律师沟通" },
      { icon: Law_Step3, title: "3.签订合同" },
      { icon: Law_Step4, title: "4.贷款全批" },
      { icon: Law_Step5, title: "5.房屋交割" },
    ],
    material: [
      { icon: Material_Step1, title: "1.地基模板" },
      { icon: Material_Step2, title: "2.木材钢材" },
      { icon: Material_Step3, title: "3.防水层" },
      { icon: Material_Step4, title: "4.电气管道" },
      { icon: Material_Step5, title: "5.隔热材料" },
      { icon: Material_Step6, title: "6.石膏板" },
      { icon: Material_Step7, title: "7.外墙涂料" },
      { icon: Material_Step8, title: "8.地板瓷砖" },
      { icon: Material_Step9, title: "9.卫浴设备" },
    ],
  };

  // Select the appropriate steps based on the industry prop
  const steps = stepsMap[industry] || [];

  return (
    <GuideIconsItem steps={steps} />
  );
};

export default GuideSteps;
