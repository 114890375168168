import React from 'react';

const ContactHeader = () => {
  return (
    <div className="text-center mb-3">
      <h1 className="text-4xl font-bold text-yellow">
        联系 <span className="text-gray-700">我们</span>
      </h1>
    </div>
  );
};

export default ContactHeader;