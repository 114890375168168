import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCompanyById, clearCachedCompany } from '../Components/Companies/companiesSlice';
import CompanyCard from '../Components/Companies/CompanyCard'
import CompanyInfoDetails from '../Components/Companies/CompanyInfoDetails'
import RelevantCompanies from '../Components/Companies/RelevantCompanies'
import Breadcrums from '../Components/Common/Breadcrums'
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { domToPng } from "modern-screenshot";

export default function SingleCompanyPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  // ref 用于引用要截图的 CompanyCard 组件
  const cardRef = useRef(null);
  const cameraIconRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { company, loadingCompany, errorCompany } = useSelector((state) => state.companies);

  useEffect(() => {
    dispatch(clearCachedCompany());
    dispatch(fetchCompanyById(id));
  }, [dispatch, id]);

  if (loadingCompany) {
    return <LoadingSpinner />;
  }

  if (errorCompany) {
    return <div>Error: {errorCompany}</div>;
  }

  if (!company) {
    return <div>Company not found.</div>;
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const takeScreenshot = () => {
    if (cardRef.current && imageLoaded) {
      // 隐藏截图按钮
      if (cameraIconRef.current) {
        cameraIconRef.current.style.display = 'none';
      }

      // 使用 modern-screenshot 进行截图
      domToPng(cardRef.current, {
        backgroundColor: "#ffffff", // 保持透明背景
        pixelRatio: 1, // 提高截图分辨率
        skipOuterContainer: true
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "company-card-screenshot.png";
          link.click();

          // 重新显示截图按钮
          if (cameraIconRef.current) {
            cameraIconRef.current.style.display = 'block';
          }
        })
        .catch((err) => {
          console.error("截图失败: ", err);

          // 重新显示截图按钮
          if (cameraIconRef.current) {
            cameraIconRef.current.style.display = 'block';
          }
        });
    }
  };


  return (
    <div className='bg-gray-100 pt-4'>
      <Breadcrums color="text-gray-700" additionalText={company.name} />
      {/* 将 CompanyCard 组件放在 ref 中 */}
      <div >
        <CompanyCard
          company={company}
          takeScreenshot={takeScreenshot}
          onImageLoad={handleImageLoad}
          cameraIconRef={cameraIconRef}
          cardRef={cardRef}
        />
      </div>
      <CompanyInfoDetails company={company} />
      <RelevantCompanies company={company} />
    </div>
  )
}
