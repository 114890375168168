import React from 'react';
import CompanyIntroduction from '../Components/Contact/CompanyIntroduction';
import ContactHeader from '../Components/Contact/ContactHeader';
import BusinessCooperation from '../Components/Contact/BusinessCooperation';
import ContactForm from '../Components/Contact/ContactForm';

const ContactPage = () => {
  return (
    <div className="bg-white">
      <CompanyIntroduction />
      
      <div className="h-full-screen p-8 flex flex-col">
        <div className="flex-shrink-0">
          <ContactHeader />
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8 md:flex flex-grow">
          <div className="flex-1">
            <BusinessCooperation />
          </div>
          <div className="flex-1">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
