import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdvertisements } from './companiesSlice'; 
import Item from './Item';

const RelevantCompanies = ({ company }) => {
  const industryMapping = {
    '地产开发': 'realestate',
    '建筑设计': 'design',
    '房屋建造': 'builder',
    '建筑材料': 'material',
    '室内装修': 'decoration',
    '房产经纪': 'agency',
    '房屋信贷': 'mortgage',
    '法律咨询': 'law',
    '会计事务': 'accounting'
  }

  const dispatch = useDispatch();
  const { advertisements = [], cachedAdvertisements } = useSelector((state) => state.companies);
  const [companyGroups, setCompanyGroups] = useState([]);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  useEffect(() => {
    if (!cachedAdvertisements) {
      dispatch(fetchAdvertisements({ region: 'All', industry: '全部' }));
    }
  }, [cachedAdvertisements, dispatch]);

  const handleHover = (id) => {
    setHoveredItemId(id);
  };

  useEffect(() => {
    const categories = ['realestate', 'design', 'builder', 'material', 'decoration', 'agency', 'mortgage', 'law', 'accounting', 'all'];

    const groups = categories.map((category) => {
      const bigAdCompanies = advertisements.filter(company =>
        company.company_level && company.company_level.includes(`BigAd_${category}`)
      );

      const smallAdCompanies = advertisements.filter(company =>
        company.company_level && company.company_level.includes(`SmallAd_${category}`)
      );

      return {
        category,
        bigAd: bigAdCompanies[0],
        smallAds: smallAdCompanies.slice(0, 4),
      };
    });

    setCompanyGroups(groups);
  }, [advertisements]);

  const matchedGroup = companyGroups.find(group => group.category === industryMapping[company.industry]) ||
    companyGroups.find(group => group.category === 'all');

  if (!matchedGroup) return null;

  const relevantCompanies = [matchedGroup.bigAd, ...matchedGroup.smallAds];

  return (
    <div>
      <div className="mt-8 pb-10">
        <hr />
        <h2 className="text-2xl text-center font-bold text-black my-4">相关公司</h2>
        <div className="flex flex-wrap mb-3 container mx-auto px-2 md:px-4 justify-center sm:justify-start -mx-2">
          {relevantCompanies.length > 0 ? (
            relevantCompanies.map((company) => (
              company && company.id ? (
                <div key={company.id} className="w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 py-2 px-2 md:px-6">
                  <Item 
                    company={company} 
                    onHover={handleHover}
                    isHovered={hoveredItemId === company.id}
                  />
                  <div className="flex flex-col md:flex-row items-center justify-center md:gap-2 py-2 mt-1 rounded-lg">
                    <p className='text-black text-xs lg:text-sm xl:text-sm'>{company.industry}</p>
                    <p className='text-black text-xs lg:text-sm xl:text-sm'>{company.location}</p>
                  </div>
                </div>
              ) : null
            ))
          ) : (
            <p className="text-center text-gray-500">没有相关公司</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RelevantCompanies;