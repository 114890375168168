import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const QuestionItem = ({ question, onClick }) => {
  return (
    <div
      className="w-full p-4 bg-white cursor-pointer hover:bg-gray-50 flex justify-between items-center"
      onClick={onClick}
    >
      {/* 左侧：问题标题和简介 */}
      <div>
        {/* 标签 */}
        <span className="inline-block bg-yellow text-white px-2 rounded-full text-xs font-semibold">
          {question.category}
        </span>
        <h2 className="text-black text-lg font-medium">{question.title}</h2>
        <p className="text-gray-500 text-sm">{question.intro}</p>
      </div>

      {/* 右侧：箭头图标 */}
      <FaArrowRight className="text-gray-400 hover:text-gray-600 transition duration-200" />
    </div>
  );
};

export default QuestionItem;