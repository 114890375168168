import React, { useState } from 'react';
import PostEvent from './PostEvent';

const EventList = () => {
    const [events, setEvents] = useState([]);

    const addEvent = (newEvent) => {
        setEvents([...events, newEvent]);
    };

    const addComment = (eventId, comment) => {
        setEvents(events.map(event =>
            event.id === eventId
                ? { ...event, comments: [...event.comments, comment] }
                : event
        ));
    };

    return (
        <div>
            <PostEvent addEvent={addEvent} />
            <div className="mt-8">
                {events.length === 0 ? (
                    <p></p>
                ) : (
                    <ul>
                        {events.map((event) => (
                            <li key={event.id} className="mb-6 p-4 bg-gray-100 shadow-md">
                                <h3 className="text-lg font-bold">{event.title}</h3>
                                <p>{event.description}</p>
                                <p className="text-gray-500 text-sm">{event.timestamp}</p>
                                <CommentSection event={event} addComment={addComment} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

const CommentSection = ({ event, addComment }) => {
    const [comment, setComment] = useState('');

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (comment.trim()) {
            addComment(event.id, { text: comment, timestamp: new Date().toLocaleString() });
            setComment('');
        }
    };

    return (
        <div className="mt-4">
            <form onSubmit={handleCommentSubmit}>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Add a comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                    comment
                </button>
            </form>
            <ul className="mt-4">
                {event.comments.map((comment, index) => (
                    <li key={index} className="text-gray-700 mb-2">
                        {comment.text}
                        <span className="text-gray-400 text-sm ml-2">{comment.timestamp}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EventList;
