import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import QRCode from '../../Assets/QRCode.png';

const Footer = () => {
  return (
    <footer className="bg-gray-black text-gray-200 py-4 sm:py-8 font-sans">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8 text-gray-200">
          {/* 第一列：联系方式和社交媒体 */}
          <div className="flex flex-col items-start">
            <img src={logo} alt="logo" className="h-8 sm:h-10 mb-2 sm:mb-4" />
            <p className="flex items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              <i className="fas fa-phone-alt mr-1 sm:mr-2"></i>
              +61 484411111
            </p>
            <p className="flex items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              <i className="fas fa-map-marker-alt mr-1 sm:mr-2"></i>
              Canberra ACT, Australia
            </p>
            <p className="flex items-center mb-1 sm:mb-2 text-xs sm:text-sm">
              <i className="fas fa-envelope mr-1 sm:mr-2"></i>
              info@qixin.com.au
            </p>
            <div className="mt-2 sm:mt-4 flex space-x-4 sm:space-x-6">
              <a href="https://www.facebook.com/people/Qi-Xin/pfbid0sdrugS454wdJaoK2YXTk2AqcnNSAXMLb5Uo8SV1MnfkpS5ztyoBLMmen4jPSPJRil/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <i className="fab fa-facebook fa-lg sm:fa-2x"></i>
              </a>
              <a href="https://x.com/QiXin_CoPtyLtd" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <i className="fab fa-twitter fa-lg sm:fa-2x"></i>
              </a>
              <a href="https://www.linkedin.com/company/qixin-co-pty-ltd/about/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <i className="fab fa-linkedin fa-lg sm:fa-2x"></i>
              </a>
              <a href="https://www.instagram.com/qixincoptyltd/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 hover:scale-110 transition transform duration-300">
                <i className="fab fa-instagram fa-lg sm:fa-2x"></i>
              </a>
            </div>
          </div>

          {/* 第二列：团队 */}
          <div className="flex flex-col">
            <h3 className="text-base sm:text-lg text-yellow font-semibold mb-1 sm:mb-2">团队</h3>
            <Link to="contact" className="w-fit">
              <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                关于企信
              </span>
            </Link>
          </div>

          {/* 第三列：业务分类 */}
          <div className="flex flex-col">
            <h3 className="text-base sm:text-lg text-yellow font-semibold mb-1 sm:mb-2">业务分类</h3>
            <div className="flex flex-col space-y-1 sm:space-y-2">
              <Link to="/companies/realestate" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  地产开发
                </span>
              </Link>
              <Link to="/companies/design" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  房屋设计
                </span>
              </Link>
              <Link to="/companies/builder" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  房屋建造
                </span>
              </Link>
              <Link to="/companies/material" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  建筑材料
                </span>
              </Link>
              <Link to="/companies/agency" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  房产经纪
                </span>
              </Link>
              <Link to="/companies/decoration" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  房屋装修
                </span>
              </Link>
              <Link to="/companies/mortgage" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  房屋信贷
                </span>
              </Link>
              <Link to="/companies/law" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  法律咨询
                </span>
              </Link>
              <Link to="/companies/accounting" className="w-fit">
                <span className="text-xs sm:text-sm hover:text-gray-200 hover:scale-110 transition transform duration-300 inline-block">
                  会计事务
                </span>
              </Link>
            </div>
          </div>

          {/* 第四列：客服 */}
          <div className="flex flex-col items-start">
            <h3 className="text-base sm:text-lg text-yellow font-semibold mb-1 sm:mb-2">客服</h3>
            <img src={QRCode} alt="QRCode" className="h-32 sm:h-40 mb-4 sm:mb-8" />
          </div>
        </div>

        <div className="text-center mt-4 sm:mt-8 text-gray-400">
          <p className="text-xs sm:text-sm">© 2024 Qixin.com.au - All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
