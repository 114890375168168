import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRegion, setIndustry, setPage } from './companiesSlice';

const data = [
  { id: 1, name: 'Location 1', region: 'ACT' },
  { id: 2, name: 'Location 2', region: 'NSW' },
  { id: 3, name: 'Location 3', region: 'VIC' },
  { id: 4, name: 'Location 4', region: 'QLD' },
  { id: 5, name: 'Location 5', region: 'WA' },
  { id: 6, name: 'Location 6', region: 'SA' },
  { id: 7, name: 'Location 7', region: 'TAS' },
  { id: 8, name: 'Location 8', region: 'NT' },
];

const FilterComponent = () => {
  const dispatch = useDispatch();

  const selectedRegion = useSelector(state => state.companies.selectedRegion);
  const selectedIndustry = useSelector(state => state.companies.selectedIndustry);

  const filteredData = selectedRegion === 'All'
    ? data
    : data.filter(item => item.region === selectedRegion);

  const regions = ['All', 'ACT', 'NSW', 'VIC', 'QLD', 'Other'];
  const regionNames = {
    'All': '全部',
    'ACT': '堪培拉 ACT',
    'NSW': '新州 NSW',
    'VIC': '维州 VIC',
    'QLD': '昆州 QLD',
    'Other':'其他 WA SA NT TAS'
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const industries = [
    { name: '全部'},
    { name: '地产开发'},
    { name: '建筑设计'},
    { name: '房屋建造'},
    { name: '建筑材料'},
    { name: '室内装修'},
    { name: '房产经纪'},
    { name: '房屋信贷'},
    { name: '法律咨询'},
    { name: '会计事务'}
  ];

  const handleRegionChange = (region) => {
    dispatch(setRegion(region));
    dispatch(setPage(1));
  };

  const handleIndustryChange = (industry) => {
    dispatch(setIndustry(industry));
    dispatch(setPage(1));
    setIsOpen(false);
  };

  return (
    <div>
      <div className="flex flex-wrap justify-center gap-2 mb-3">
        {regions.map(region => (
          <button 
            key={region}
            className={`px-4 py-2 rounded-2xl text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm ${selectedRegion === region ? 'bg-yellow text-white' : 'bg-gray-300 text-gray-600'}`}
            onClick={() => handleRegionChange(region)}
          >
            {regionNames[region]}
          </button>
        ))}
      </div>

      {/* Dropdown menu for industries */}
      <div className="flex justify-center">
        <div className="relative inline-block text-left">
          <button
            ref={buttonRef}
            onClick={toggleDropdown}
            className="inline-flex justify-center w-[120px] rounded-md border border-yellow shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none"
          >
            {selectedIndustry || '选择行业'}
            <svg className="-mr-1 ml-2 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="blue" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Dropdown menu */}
          {isOpen && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-1 w-[120px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
            >
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {industries.map((industry) => (
                  <button
                    key={industry.name}
                    className={`block w-full text-center px-4 py-1 text-sm text-gray-500 hover:bg-gray-200 ${selectedIndustry === industry.name ? 'bg-gray-200' : ''}`}
                    role="menuitem"
                    onClick={() => { handleIndustryChange(industry.name); }}
                  >
                    {industry.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>  
    </div>
  );
};

export default FilterComponent;