import React, { useState } from 'react';

const PostEvent = ({ addEvent }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [cost, setCost] = useState('');
    const [organizer, setOrganizer] = useState('');
    const [location, setLocation] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [details, setDetails] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventUrl, setEventUrl] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const newEvent = {
            id: Date.now(),
            title,
            description,
            address,
            city,
            details,
            startDate,
            endDate,
            startTime,
            endTime,
            cost,
            organizer,
            location,
            eventUrl,
            image,
            comments: [],
            timestamp: new Date().toLocaleString(),
        };
        addEvent(newEvent);
        // Reset form fields
        setTitle('');
        setDescription('');
        setAddress('');
        setCity('');
        setDetails('');
        setStartDate('');
        setEndDate('');
        setStartTime('');
        setEndTime('');
        setCost('');
        setOrganizer('');
        setLocation('');
        setEventUrl('');
        setImage(null);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <div className="p-4 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">发布活动</h2>
            <form onSubmit={handleSubmit}>
                {/* 活动名称 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">活动名称：</label>
                    <input
                        id="title"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>

                {/* 地址和城市在同一行 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">地址：</label>
                        <input
                            id="address"
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">城市：</label>
                        <input
                            id="city"
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* 活动详情 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">活动详情：</label>
                    <textarea
                        id="description"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>

                {/* 开始日期和结束日期 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">开始日期：</label>
                        <input
                            id="startDate"
                            type="date"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">结束日期：</label>
                        <input
                            id="endDate"
                            type="date"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* 开始时间和结束时间 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">开始时间：</label>
                        <input
                            id="startTime"
                            type="time"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="p-2 border-gray-300 rounded text-sm w-4/5">结束时间：</label>
                        <input
                            id="endTime"
                            type="time"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* 费用 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">费用：</label>
                    <input
                        id="cost"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        required
                    />
                </div>

                {/* 主办方 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">主办方：</label>
                    <input
                        id="organizer"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={organizer}
                        onChange={(e) => setOrganizer(e.target.value)}
                        required
                    />
                </div>
                {/* 活动链接 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">活动链接：</label>
                    <input
                        id="eventUrl"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={eventUrl}
                        onChange={(e) => setEventUrl(e.target.value)}
                    />
                </div>

                {/* 上传图片 */}
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-sm w-4/5">上传图片：</label>
                    <input
                        id="image"
                        type="file"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        onChange={handleImageChange}
                    />
                </div>

                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                        发布
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PostEvent;
