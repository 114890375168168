import React, { useState } from 'react';

const HomepageModification = ({ companyData, updateCompany }) => {
    // 定义状态变量
    const [ABN, setABN] = useState(companyData.ABN || '');
    const [briefIntro, setBriefIntro] = useState(companyData.brief_intro || '');
    const [companyNameCn, setCompanyNameCn] = useState(companyData.company_name_cn || '');
    const [companyNameEn, setCompanyNameEn] = useState(companyData.company_name_en || '');
    const [contactPerson, setContactPerson] = useState(companyData.contact_person || '');
    const [email, setEmail] = useState(companyData.email || '');
    const [phoneNumber, setPhoneNumber] = useState(companyData.phone_number || '');
    const [wechatId, setWechatId] = useState(companyData.wechat_id || '');
    const [location, setLocation] = useState(companyData.location || '');
    const [imageFiles, setImageFiles] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => file.type.startsWith('image/') && imageFiles.length + validFiles.length <= 5);
        setImageFiles(prev => [...prev, ...validFiles]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedCompanyData = {
            ABN,
            brief_intro: briefIntro,
            company_name_cn: companyNameCn,
            company_name_en: companyNameEn,
            contact_person: contactPerson,
            email,
            phone_number: phoneNumber,
            wechat_id: wechatId,
            location,
            images: imageFiles
        };
        updateCompany(updatedCompanyData);
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">修改公司信息</h2>
            <form onSubmit={handleSubmit}>

                {/* 公司中文名和英文名并排 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">公司中文名</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={companyNameCn}
                            onChange={(e) => setCompanyNameCn(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">公司英文名</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={companyNameEn}
                            onChange={(e) => setCompanyNameEn(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* 公司简介 */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm">公司简介</label>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={briefIntro}
                        onChange={(e) => setBriefIntro(e.target.value)}
                        required
                    />
                </div>

                {/* ABN */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm">ABN</label>
                    <input
                        type="number"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={ABN}
                        onChange={(e) => setABN(e.target.value)}
                    />
                </div>

                {/* 联系人和邮箱并排 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">联系人</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={contactPerson}
                            onChange={(e) => setContactPerson(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">邮箱</label>
                        <input
                            type="email"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* 电话号码和微信号并排 */}
                <div className="flex mb-4 space-x-4">
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">电话号码</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm">微信号</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                            value={wechatId}
                            onChange={(e) => setWechatId(e.target.value)}
                        />
                    </div>
                </div>

                {/* 地址 */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm">地址</label>
                    <input
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-2xl text-md"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>

                {/* 图片上传 */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm">公司Logo和展示图片 (最多1张)</label>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        className="p-2 border border-gray-300 rounded-2xl text-md"
                    />
                    <div className="mt-2">
                        {imageFiles.map((file, index) => (
                            <div key={index} className="text-gray-600">{file.name}</div>
                        ))}
                    </div>
                </div>

                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                        更新修改
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HomepageModification;
