import React from 'react';
import FormattedText from '../Common/FormattedText';

const CompanyInfo = ({ company }) => {

  return (
    <div className="relative w-80% mx-[20px] md:mx-[50px] bg-white border border-gray-200 shadow-lg rounded-lg p-3 md:p-8 pt-12 z-10 -mt-[150px] mb-10">
      <div className="flex justify-center flex-col items-center md:flex-row mt-[80px] md:mt-[120px] gap-x-6">
        {/* 上面部分 Logo */}
        <div className={`w-12 h-12 md:w-24 md:h-24 mt-4`}>
          <img
            src={company.image}  
            alt="Company Logo"
            className="w-full h-full object-cover rounded-full shadow-lg"
          />
        </div>

        {/* 公司名称 */}
        <div className="text-center mt-4">
          <h1 className="text-lg mx-2 md:text-2xl font-bold text-gray-800">
            {company.name}
          </h1>
        </div>
      </div>
      
      {/* 主营业务部分 */}
      <div className="flex justify-center space-x-4 mt-4 mb-6">
        <div className="bg-lightyellow text-gray-800 text-sm px-3 py-1 rounded-full">
          {"所在地区：" + company.location + company.location_en}
          {" | "} 
          {"主营业务：" + company.industry}
        </div>
      </div>

      {/* 公司简介部分 */}
      <div className="text-left mb-6">
        <h2 className="text-md md:text-xl font-bold text-gray-800 mb-4">公司介绍</h2>
        <div className="text-base md:text-md text-gray-600">
          <FormattedText text={company.detail} />
        </div>
      </div>

      {/* 产品展示部分 */}
      {company.product && (<div className="text-left">
        <h2 className="text-md md:text-xl font-bold text-gray-800 mb-2">产品展示</h2>
        <p className="text-base md:text-md text-gray-600">
          这里展示了我们的主要产品和服务，包括最新的技术解决方案和创新产品。我们致力于提供最前沿的科技，满足不同客户的需求。我们的产品涵盖了从软件开发到硬件解决方案的各个领域。
        </p>
      </div>)}
    </div>
  );
};

export default CompanyInfo;