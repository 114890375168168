import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async ({ region, industry }) => {
    const params = new URLSearchParams();

    if (region !== 'All') {
      params.append('state_en', region);
    }
    if (industry !== '全部') {
      params.append('industrial_cn', industry);
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/company?${params.toString()}`);

    const data = await response.json();

    return data;
  }
);

export const fetchCompanyById = createAsyncThunk(
  'companies/fetchCompanyById',
  async (id) => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/company/${id}`);

    const data = await response.json();

    return data;
  }
);

// 首页业内好评公司
export const fetchLandingPageCompanies = createAsyncThunk(
  'companies/fetchLandingPageCompanies',
  async () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/company`);
    const data = await response.json();

    return data;
  }
);

export const fetchAdvertisements = createAsyncThunk(
  'companies/fetchAdvertisements',
  async ({ region = 'All', industry = '全部' }) => {
    const params = new URLSearchParams();

    if (region !== 'All') {
      params.append('state_en', region);
    }
    if (industry !== '全部') {
      params.append('industrial_cn', industry);
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/company?${params.toString()}`);
    const data = await response.json();

    return data;
  }
);

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    landingPageCompanies: [],
    company: null,
    advertisements: [],
    loadingCompanies: false,
    loadingLandingPageCompanies: false,
    loadingCompany: false,
    loadingAdvertisements: false,
    errorCompanies: null,
    errorLandingPageCompanies: null,
    errorCompany: null,
    errorAdvertisements: null,
    selectedRegion: 'All',
    selectedIndustry: '全部',
    currentPage: 1,
    totalPages: 1,
    cachedCompanies: false,
    cachedLandingPageCompanies: false,
    cachedCompany: false,
    cachedAdvertisements: false, // 添加缓存标记
  },
  reducers: {
    setRegion: (state, action) => {
      state.selectedRegion = action.payload;
      state.currentPage = 1; // 重置当前页数
      state.cachedCompanies = false
      state.cachedAdvertisements = false; // 改变区域时重置缓存状态
    },
    setIndustry: (state, action) => {
      state.selectedIndustry = action.payload;
      state.currentPage = 1; // 重置当前页数
      state.cachedCompanies = false; 
      state.cachedAdvertisements = false; // 改变行业时重置缓存状态
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
      state.cachedCompanies = false; // 改变页数时重置缓存状态
    },
    clearCachedCompany: (state) => {
      state.company = null;
      state.cachedCompany = false;
      state.errorCompany = null;
      state.loadingCompany = true;
    },  
  },
  extraReducers: (builder) => {
    builder
      // 公司页
      .addCase(fetchCompanies.pending, (state) => {
        state.loadingCompanies = true;
        state.errorCompanies = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.totalPages = Math.ceil(action.payload.length / 12) // 计算总页数
        state.loadingCompanies = false;
        state.cachedCompanies = true; // 数据加载成功后设置缓存为 true
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loadingCompanies = false;
        state.errorCompanies = action.error.message;
      })

      // 广告位
      .addCase(fetchAdvertisements.pending, (state) => {
        state.loadingAdvertisements = true;
        state.errorAdvertisements = null;
      })
      .addCase(fetchAdvertisements.fulfilled, (state, action) => {
        state.advertisements = action.payload;
        state.loadingAdvertisements = false;
        state.cachedAdvertisements = true;
      })
      .addCase(fetchAdvertisements.rejected, (state, action) => {
        state.loadingAdvertisements = false;
        state.errorAdvertisements = action.error.message;
      })

      // 单个公司页面
      .addCase(fetchCompanyById.pending, (state) => {
        state.loadingCompany = true;
        state.errorCompany = null;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.company = action.payload;
        state.loadingCompany = false;
        state.cachedCompany = true;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.loadingCompany = false;
        state.errorCompany = action.error.message;
      })

      // 首页公司
      .addCase(fetchLandingPageCompanies.pending, (state) => {
        state.loadingLandingPageCompanies = true;
        state.errorLandingPageCompanies = null;
      })
      .addCase(fetchLandingPageCompanies.fulfilled, (state, action) => {
        state.landingPageCompanies = action.payload;
        state.loadingLandingPageCompanies = false;
        state.cachedLandingPageCompanies = true;
      })
      .addCase(fetchLandingPageCompanies.rejected, (state, action) => {
        state.loadingLandingPageCompanies = false;
        state.errorLandingPageCompanies = action.error.message;
      });
  },
});

export const { setRegion, setIndustry, setPage, clearCachedCompany } = companySlice.actions;
export default companySlice.reducer;
