import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyById, clearCachedCompany } from '../Components/Companies/companiesSlice';
import { fetchQuestions, searchQuestions, setIndustry } from '../Components/QA/questionSlice';
import FormattedText from '../Components/Common/FormattedText';
import Item from '../Components/Companies/Item';
import LoadingSpinner from '../Components/Common/LoadingSpinner';

const Sponsors = {
  '法律咨询': 231,       // 陈宇律师楼
  '房屋信贷': 349,       // Wen Mortgage
  '会计事务': 97,        // Solution In

  // 房屋建造：Starry
  // 房屋设计：BJ Architect
  // 房屋经纪：U plus
  // 这三个下周再放

  // 剩下的暂时都不放
};


const DetailedQAPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { questions } = useSelector((state) => state.questions);
  const { company, loadingCompany, errorCompany } = useSelector((state) => state.companies);
  const question = questions.find((q) => q.id === id);

  const [sponsorId, setSponsorId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  useEffect(() => {
    if (question) {
      const companyId = Sponsors[question.category];
      if (companyId) {
        setSponsorId(companyId);
        dispatch(fetchCompanyById(companyId));
      }
    }
  }, [question, dispatch]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearCachedCompany());
  //   };
  // }, [dispatch]);

  if (!question) {
    return <LoadingSpinner />;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-yellow p-4 flex items-center">
        <Link
          to="/QA"
          className="text-white hover:text-gray-200 transition duration-200 flex items-center"
          aria-label="Go back"
        >
          <FaArrowLeft size={24} />
          <span className="ml-2 text-lg font-semibold">Q&A</span>
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className='flex flex-col md:flex-row justify-between'>
            <div>
              <span className="inline-block bg-yellow text-white px-3 py-1 rounded-full text-sm font-semibold mb-4">
                {question.category}
              </span>

              <h2 className="text-2xl font-bold text-gray-800 mb-4">{question.title}</h2>
            </div>

            <div className='flex justify-center md:justify-end'>
              {/* 赞助商信息 */}
              {sponsorId && (
                <div className="max-w-64">
                  {loadingCompany ? (
                    <p>Loading sponsor...</p>
                  ) : errorCompany ? (
                    <p>Error loading sponsor.</p>
                  ) : (
                    company && 
                    <>
                    <Item 
                      company={company} 
                      onHover={setHoveredItemId}
                      isHovered={hoveredItemId === company.id}
                    />
                    <p className='mt-2 text-center font-medium text-gray-600'>本条QA由{company.name}赞助提供</p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">回答</h3>
            <p className="text-gray-700">
              <FormattedText text={question.answer || '暂无回答'} />
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DetailedQAPage;
