import React from 'react';
import { Link } from 'react-router-dom';

const SearchResults = ({ results, onSelect, notFound }) => {
  return (
    <div className="w-full mt-2 p-4 bg-white shadow-md rounded-lg max-w-xl max-h-[600px] overflow-y-auto">
      {notFound ? (
        <div className="text-gray-600 text-center py-2">
          没有找到相关公司。
        </div>
      ) : (
        <ul>
          {results.map((company) => (
            <li key={company.id} className="py-2 border-b flex">
              <Link 
                to={`/company/${company.id}`}
                state={{ company }}
                className="flex-1 text-center"
                onClick={() => onSelect()} // 点击时关闭搜索框
              >
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.name}
                </p>
              </Link>
              <div className="w-1/4 text-center">
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.industry}
                </p>
              </div>
              <div className="w-1/4 text-center">
                <p className="text-gray-600 text-xs sm:text-sm md:text-md">
                  {company.location}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;
