import React from 'react';

const BusinessCooperation = () => {
  return (
    <div className="md:w-3/4">
      <h2 className="text-3xl font-semibold text-gray-700">商务合作</h2>
      <p className="text-gray-500 mt-4">
        企业信息录入，更新，行业上下游咨询，相关工作职位咨询，活动合作举办，有任何需要咨询合作的地方，敬请随时联系。
      </p>
    </div>
  );
};

export default BusinessCooperation;