import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestNews } from './newsSlice';

const News = () => {
  const dispatch = useDispatch(); // 用于分发 Redux 异步操作
  const { newsList, loading, error } = useSelector((state) => state.news); // 从 Redux 中选择状态

  useEffect(() => {
    dispatch(fetchLatestNews()); // 触发获取新闻的异步操作
  }, []);

  return (
    <div className="bg-gray-200 p-4 h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold text-black">最新新闻</h2>
        <Link
          to="/news"
          className="text-darkyellow hover:text-yellow text-sm font-semibold transition-colors duration-300 ease-in-out"
        >
          查看全部 &gt;&gt;&gt;
        </Link>
      </div>
      <div className="flex-grow overflow-hidden">
        <div className="h-full flex flex-col">
          {loading ? (
            <p>加载中...</p> // 显示加载状态
          ) : error ? (
            <p className="text-red-500">{error}</p> // 显示错误信息
          ) : newsList.length > 0 ? (
            newsList.map((item) => (
              <Link
                key={item.news_id}
                to={`/news/${item.news_id}`}
                className={`bg-white rounded-lg text-gray-black flex flex-col justify-between p-3 shadow-md hover:bg-yellow hover: transition duration-100 flex-grow mb-2`}
              >
                <h3 className="text-sm font-semibold">{item.Title}</h3>
                <span className="text-xs text-gray-500">{new Date(item.Date).toLocaleDateString()}</span>
              </Link>
            ))
          ) : (
            <p className="text-gray-500">没有新闻可显示。</p> // 如果没有新闻
          )}
        </div>
      </div>
    </div>
  );
};

export default News;
