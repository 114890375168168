import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestions, searchQuestions, setIndustry } from '../Components/QA/questionSlice';
import SearchBar from '../Components/QA/SearchBar';
import QuestionItem from '../Components/QA/QuestionItem';
import Navbar from '../Components/Common/Navbar';
import { Link } from 'react-router-dom';
import Breadcrums from '../Components/Common/Breadcrums';

const industries = [
  { name: '全部', path: '/companies' },
  { name: '地产开发', path: '/companies/realestate' },
  { name: '建筑设计', path: '/companies/design' },
  { name: '房屋建造', path: '/companies/builder' },
  { name: '建筑材料', path: '/companies/material' },
  { name: '室内装修', path: '/companies/decoration' },
  { name: '房产经纪', path: '/companies/agency' },
  { name: '房屋信贷', path: '/companies/mortgage' },
  { name: '法律咨询', path: '/companies/law' },
  { name: '会计事务', path: '/companies/accounting' },
];

const QAPage = () => {
  const dispatch = useDispatch();
  const { questions, cached, loading, error, selectedIndustry } = useSelector((state) => state.questions);
  const [selectedCategory, setSelectedCategory] = useState('全部');
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(7);

  useEffect(() => {
    if (!cached || selectedIndustry !== selectedCategory) {
      dispatch(fetchQuestions());
    }
  }, [dispatch, selectedIndustry, cached, selectedCategory]);

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchQuestions({ queryKeyword: searchQuery }));
    } else {
      dispatch(fetchQuestions());
    }
  }, [dispatch, cached, searchQuery]);

  const filteredQuestions = selectedCategory === '全部'
    ? questions
    : questions.filter((q) => q.category === selectedCategory);

  const handleIndustryChange = (industry) => {
    setSelectedCategory(industry.name);
    dispatch(setIndustry(industry.name));
    setVisibleCount(7);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      dispatch(fetchQuestions());
    } else {
      dispatch(searchQuestions({ queryKeyword: query }));
    }
    setVisibleCount(7);
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 7);
  };

  return (
    <>
      <Navbar />
      <div className="bg-gray-100 -mb-12">
        <Breadcrums color="text-gray-700" additionalText="QA名字" />
      </div>
      <div className="bg-gray-100 min-h-screen flex flex-col items-center pb-12 p-4">
        <div className="w-full max-w-4xl bg-gray-100 pt-20 flex justify-center items-center">
          <SearchBar onSearch={handleSearch} />
        </div>
        <div className="w-full bg-gray-100 p-2 sm:p-4">
          <div className="flex flex-wrap justify-center gap-2 sm:gap-3">
            {industries.map((industry) => (
              <button
                key={industry.name}
                className={`px-4 py-2 rounded-full text-sm ${selectedIndustry === industry.name
                    ? 'bg-yellow text-white'
                    : 'bg-gray-200 text-gray-700'
                  } hover:bg-yellow hover:text-white transition duration-200`}
                onClick={() => handleIndustryChange(industry)}
              >
                {industry.name}
              </button>
            ))}
          </div>
        </div>

        {error && <p className="mt-4">{error}</p>}

        {loading && <p className="mt-4">加载中...</p>}

        {!loading && !error && questions.length > 0 && (
          <div className="w-full max-w-4xl bg-gray-100 mt-4">
            <div className="p-4 m-4 bg-white rounded-2xl">
              {filteredQuestions.slice(0, visibleCount).map((question) => (
                <Link key={question.id} to={`/QA/${question.id}`}>
                  <QuestionItem question={question} />
                </Link>
              ))}
            </div>
          </div>
        )}

        {!loading && !error && filteredQuestions.length > visibleCount && (
          <button
            className="mt-6 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg"
            onClick={handleLoadMore}
          >
            加载更多...
          </button>
        )}
      </div>
    </>
  );
};

export default QAPage;
