import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from './authSlice';
import { useNavigate } from 'react-router-dom';

const LoginComponent = ({ closeLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [version, setVersion] = useState('个人版'); 
  const [error, setError] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); 
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
      const endpoint = isLogin ? '/auth/login' : '/auth/register'; 
      const response = await fetch(`${baseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(isLogin ? 'Login failed. Please check your credentials.' : 'Registration failed. Please try again.');
      }

      const data = await response.json();

      if (isLogin) {
        const { idToken, refreshToken } = data;

        if (rememberMe) {
          localStorage.setItem('authToken', idToken);
          localStorage.setItem('refreshToken', refreshToken);
        } else {
          sessionStorage.setItem('authToken', idToken);
          sessionStorage.setItem('refreshToken', refreshToken);
        }

        dispatch(login(idToken));
        closeLogin();
        navigate('/companyCRM');
      } else {
        setIsLogin(true);
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  const toggleVersion = () => {
    setVersion((prevVersion) => (prevVersion === '个人版' ? '企业版' : '个人版'));
  };

  const switchToRegister = () => {
    setIsLogin(false);
  };

  const switchToLogin = () => {
    setIsLogin(true);
  };

  return (
    <div onClick={(e) => e.target === e.currentTarget && closeLogin()}  className={`fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-blur-lg transition-all duration-500 ${animate ? 'opacity-100' : 'opacity-0'}`}>
      <div className={`bg-white shadow-md rounded-lg p-8 max-w-md w-full transform transition-transform duration-500 ${animate ? 'translate-y-0' : 'translate-y-10'}`}>
        <h2 className="text-2xl font-bold text-center text-gray-900 mb-6">
          {isLogin ? '登录帐号' : '注册帐号'}
        </h2>

        {/* Slider for switching between 个人版 and 企业版 */}
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 font-semibold rounded-l-lg ${version === '个人版' ? 'bg-yellow text-white' : 'bg-gray-200 text-gray-700'}`}
            onClick={() => setVersion('个人版')}
          >
            个人版
          </button>
          <button
            className={`px-4 py-2 font-semibold rounded-r-lg ${version === '企业版' ? 'bg-yellow text-white' : 'bg-gray-200 text-gray-700'}`}
            onClick={() => setVersion('企业版')}
          >
            企业版
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              邮件地址
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 mb-2">
              密码
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          {!isLogin && (
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">
                确认密码
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow text-gray-700"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>
          )}

          {isLogin && (
            <div className="mb-4 flex justify-between items-center">
              <label className="text-sm text-gray-600">
                <input type="checkbox" className="mr-2" /> 记住我
              </label>
              <a href="/forgot-password" className="text-sm text-gray-600 hover:text-blue hover:underline">
                忘记密码?
              </a>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-yellow text-white font-bold py-2 px-4 rounded-lg hover:bg-yellow-600 hover:text-gray-100 transition duration-200"
          >
            {isLogin ? '登录' : '注册'}
          </button>
        </form>

        <p className="text-center text-gray-600 mt-4">
          {isLogin ? (
            <>
              没有帐户?{' '}
              <span onClick={switchToRegister} className="text-gray-600 hover:text-blue hover:underline cursor-pointer">
                点击注册
              </span>
            </>
          ) : (
            <>
              已有帐户?{' '}
              <span onClick={switchToLogin} className="text-gray-600 hover:text-blue hover:underline cursor-pointer">
                点击登录
              </span>
            </>
          )}
        </p>

        {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default LoginComponent;