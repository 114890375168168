import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // 导入 Link 组件
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import Breadcrums from '../Components/Common/Breadcrums';

// 新闻文章组件
const NewsArticle = ({ id, title, date }) => {
  return (
    <Link to={`/news/${id}`}> {/* 将整个新闻项包裹在 Link 中 */}
      <div className="p-6 bg-white shadow-md rounded-lg overflow-hidden h-full flex flex-col justify-between">
        {/* 新闻标题和日期 */}
        <div className="flex items-center mb-4">
          {/* <FaUser className="text-gray-500 mr-2 text-2xl" /> 添加 FaUser 图标 */}
          <h2 className="text-xl font-bold text-gray-800">
            {title.length > 30 ? title.slice(0, 30) + '...' : title} {/* 限制标题长度 */}
          </h2>
        </div>
        <div className="flex items-center">
          <FaCalendarAlt className="text-gray-500 mr-2" /> {/* 添加 FaCalendarAlt 图标 */}
          <p className="text-sm text-gray-600">
            {new Date(date).toLocaleDateString('zh-CN', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })} {/* 格式化日期 */}
          </p>
        </div>
      </div>
    </Link>
  );
};

// 新闻网格组件
const NewsLandingPage = () => {

  const [news, setNews] = useState([]); // 状态管理，保存新闻数据
  const [loading, setLoading] = useState(true); // 状态管理，加载状态
  const [error, setError] = useState(null); // 状态管理，错误信息

  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${baseUrl}/news`);
        if (!response.ok) {
          throw new Error('网络错误，无法获取新闻。');
        }
        const data = await response.json();
        setNews(data); // 将获取到的新闻数据存入状态
      } catch (err) {
        setError(err.message); // 处理错误
      } finally {
        setLoading(false); // 加载完成
      }
    };

    fetchNews();
  }, [baseUrl]); // 当 baseUrl 变化时重新请求新闻
  // 示例新闻数据

  return (
    <div className="bg-gray-100 pt-4">
      <Breadcrums color="text-gray-700" />
      <div className="font-sans py-8 px-4">
        <div className="max-w-6xl mx-auto">
          {/* 标题部分 */}
          <div className="text-center mb-12">
            <h1 className="text-4xl text-yellow font-bold text-gray-800 mb-4">最新新闻</h1>
            <p className="text-lg text-gray-600">获取最新的新闻和更新，了解我们最新的动态和信息。</p>
          </div>

          {/* 新闻网格部分 */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {news.map((item) => (
              <NewsArticle
                id={item.news_id} // 传递 id 属性
                title={item.Title}
                date={item.Date}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLandingPage;
