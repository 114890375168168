import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const FeatureBox = ({ title, to, Icon }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`w-1/3 sm:w-1/4 lg:w-[11.1%] transition-opacity duration-1000 ease-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'}`}>
      <div className="flex flex-col items-center">
        <Link 
          to={to} 
          className="w-full block bg-black text-gray-300 p-2 text-center shadow-md rounded-3xl hover:bg-yellow transition-colors duration-300"
        >
          <div className="aspect-w-28 aspect-h-28 w-full flex justify-center items-center">
            <Icon className="w-28 h-28 object-contain" />
          </div>
        </Link>
        <h2 className="text-sm sm:text-base lg:text-lg font-semibold mt-2 text-white text-center">{title}</h2>
      </div>
    </div>
  );
}

export default FeatureBox;
