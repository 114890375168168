import React, { useState, useEffect } from 'react';

const FlipCard = ({ digit, label }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className="px-[7px] sm:px-8"
            style={{ width: '25%' }}
        >
            <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={`transition-transform transition-opacity duration-1000 ease-out ${
                    hovered ? 'opacity-100 translate-y-0' : 'opacity-100 translate-y-3'
                }`}
            >
                <div className="flex flex-col gap-1 items-center">
                    <div className="flip-card bg-white shadow-md rounded-lg flex items-center justify-center w-full aspect-square p-0"
                        style={{ maxWidth: '100%', width: '100%' }}
                    >
                        <div className="flip-card-front font-bold text-[4vw] sm:text-xl md:text-2xl lg:text-3xl">
                            {digit}
                        </div>
                    </div>
                    <div className="flip-card-label text-[2vw] sm:text-xs md:text-sm text-center">
                        {label}
                    </div>
                </div>
            </div>
        </div>
    );
};

const FancyCountdown = ({ targetDate, staticTime }) => {
    const [timeLeft, setTimeLeft] = useState(staticTime || calculateTimeLeft());

    function calculateTimeLeft() {
        if (!targetDate) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

        const difference = +new Date(targetDate) - +new Date();
        
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    useEffect(() => {
        if (staticTime) {
            setTimeLeft(staticTime);
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate, staticTime]);

    const addLeadingZero = (value) => {
        return String(value).padStart(2, '0');
    };

    return (
        <div className="fancy-countdown flex justify-center items-stretch w-full">
            <div className="flex justify-center w-full max-w-[100%] sm:max-w-[90%] md:max-w-2xl lg:max-w-3xl mx-auto">
                <FlipCard digit={addLeadingZero(timeLeft.days)} label="DAYS" />
                <FlipCard digit={addLeadingZero(timeLeft.hours)} label="HOURS" />
                <FlipCard digit={addLeadingZero(timeLeft.minutes)} label="MINUTES" />
                <FlipCard digit={addLeadingZero(timeLeft.seconds)} label="SECONDS" />
            </div>
        </div>
    );
};

const CountdownTimer = ({ date, time }) => {
    console.log("Input date and time:", date, time);

    const calculateTargetDate = (startDateStr, startTimeStr) => {
        // 确保 startDateStr 和 startTimeStr 是字符串
        if (typeof startDateStr !== 'string' || typeof startTimeStr !== 'string') {
            console.error('Invalid date or time string:', { startDateStr, startTimeStr });
            return null;
        }
    
        // 解析日期字符串，格式是 DD/MM/YYYY
        const [day, month, year] = startDateStr.split('/');
        if (!day || !month || !year) {
            console.error('Invalid date format:', startDateStr);
            return null;
        }
    
        // 解析时间字符串，格式是 上午/下午HH:MM
        const timeRegex = /([上午|下午])(\d{2}):(\d{2})/;
        const timeMatch = startTimeStr.match(timeRegex);
        
        if (!timeMatch) {
            console.error('Invalid time format:', startTimeStr);
            return null;
        }
    
        let [_, period, hours, minutes] = timeMatch;
    
        // 处理上午/下午的 12 小时制转换
        hours = parseInt(hours);
        if (period === '下午' && hours !== 12) {
            hours += 12;
        } else if (period === '上午' && hours === 12) {
            hours = 0;
        }
    
        // 创建目标日期对象
        const targetDate = new Date(year, month - 1, day, hours, parseInt(minutes));
    
        return targetDate;
    };

    // 计算目标日期
    const targetDate = calculateTargetDate(date, time);

    console.log("Calculated target date:", targetDate);

    // 检查是否过期
    const now = new Date();
    const isExpired = targetDate ? targetDate <= now : true;

    console.log("Is expired:", isExpired);

    // 创建一个表示所有时间为 0 的对象
    const zeroTime = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    return (
        <div className="container mx-auto my-4 sm:my-6 md:my-10 px-1 sm:px-2 md:px-4">
            <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-center mb-2 sm:mb-4">
                活动倒计时
            </h2>
            {targetDate ? (
                <>
                    <FancyCountdown 
                        targetDate={isExpired ? null : targetDate.toISOString()} 
                        staticTime={isExpired ? zeroTime : null}
                    />
                    {isExpired && <div className="text-darkyellow text-center mt-8">活动已结束</div>}
                </>
            ) : (
                <div>无法计算倒计时，请检查输入的日期和时间格式。</div>
            )}
        </div>
    );
};

export default CountdownTimer;
